import React, { useState } from "react";
import { API_URL2 } from "../../api/APIUrl";

// Need to make a query here to get the user associated with the email address provided.
// I would need a separate token for password reset... this would expire every 30 min when used.
// Each Token will be associated with a user

const PasswordResetFormFinal = () => {
  const [formState, setFormState] = useState({
    verifCode: "",
    password1: "",
    password2: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (formState.password1 === formState.password2) {
      try {
        //add fetch to change pw here
        await fetch(`${API_URL2}/api/users/changepw/nologin`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: formState.verifCode,
            password: formState.password1,
          }),
        });
      } catch (err) {
        console.log(err);
      }
      alert("Password successfully changed!");
    } else
      alert(
        "Please check to see if your password match or if your token is correct."
      );
  };

  return (
    <div className="login-form-box">
      <h3 className="mb-30">Reset Your Password</h3>
      <form className="login-form" onSubmit={handleFormSubmit}>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="Verification Code"
            name="verifCode"
            id="verifCode"
            required
            onChange={handleChange}
          />
        </div>
        <div className="input-box mb--30">
          <input
            type="password"
            placeholder="New Password"
            name="password1"
            id="password1"
            required
            onChange={handleChange}
          />
        </div>
        <div className="input-box mb--30">
          <input
            type="password"
            placeholder="Re-Enter New Password"
            name="password2"
            id="password2"
            required
            onChange={handleChange}
          />
        </div>
        <button className="rn-btn btn-default w-100 mb--30" type="submit">
          <span>Reset Password</span>
        </button>
      </form>
    </div>
  );
};

export default PasswordResetFormFinal;
