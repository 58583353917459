import React from "react";
import Layout from "../component/Layout";
import PasswordResetForm from "../component/forms/PasswordResetForm";

const PasswordReset = () => {
  return (
    <>
      <Layout>
        <div className="login-register-page-wrapper section-gap bg-color-white">
          <div className="container checkout-page-style">
            <div className="row g-5 text-center">
              <div className="col-lg-6">
                <PasswordResetForm />
              </div>
              <div className="col-lg-6">
                <img
                  src="/images/landing-demo/home-05.jpg"
                  alt=" placeholder images"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PasswordReset;
