import React, { useEffect, useState } from "react";
import { API_URL2 } from "../api/APIUrl";
import AdminSideBar from "../component/admin/AdminSideBar";
import AdminUsers from "../component/admin/AdminUsers";
import AdminChangePW from "../component/admin/AdminChangePW";
import AdminUserList from "../component/admin/AdminUserList";
import AdminPostList from "../component/admin/AdminPostList";
import AdminAnalytics from "../component/admin/AdminAnalytics";
import AdminAnnouncements from "../component/admin/AdminAnnouncement";

function Test() {
  const [users, setUsers] = useState([]);
  const [visibleChangePw, setVisibleChangePw] = useState(true);
  const [visibleCards, setVisibleCards] = useState(true);
  const [visibleUserList, setVisibleUserList] = useState(false);
  const [visiblePostList, setVisiblePostList] = useState(false);
  const [visibleAnalytics, setVisibleAnalytics] = useState(false);
  const [visibleAnnouncements, setVisibleAnnouncements] = useState(false);
  const [sideVisible, setSideVisible] = useState(false);

  const fetchdata = async () => {
    const res = await fetch(`${API_URL2}/api/users`);
    const res2 = await res.json();
    setUsers(res2);
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const VisibilityCard = (bool) => {
    setVisibleCards(bool);
  };
  const VisibilityUserList = (bool) => {
    setVisibleUserList(bool);
  };
  const VisibilityPostList = (bool) => {
    setVisiblePostList(bool);
  };
  const VisibilityChangePw = (bool) => {
    setVisibleChangePw(bool);
  };

  const VisibilityAnalytics = (bool) => {
    setVisibleAnalytics(bool);
  };
  const VisibilityAnnouncements = (bool) => {
    setVisibleAnnouncements(bool);
  };
  // reformat everything and make the admin page a separate profile page link depending on if they are admin or not.
  // so id have to add a section where they can edit profile
  // see all users
  // see all posts and edit/delete a post
  //log out
  // go back home

  return (
    <>
      <div className="admin">
        <div className="container-admin">
          <AdminSideBar
            VisibilityCard={VisibilityCard}
            VisibilityUserList={VisibilityUserList}
            VisibilityPostList={VisibilityPostList}
            VisibilityChangePw={VisibilityChangePw}
            VisibilityAnalytics={VisibilityAnalytics}
            VisibilityAnnouncements={VisibilityAnnouncements}
            VisibilitySide={sideVisible}
            VisibilityClose={setSideVisible}
          />
          <main>
            <AdminUsers visible={visibleCards} />
            <AdminChangePW visible={visibleChangePw} />
            <AdminUserList visible={visibleUserList} />
            <AdminPostList visible={visiblePostList} />
            <AdminAnalytics visible={visibleAnalytics} />
            <AdminAnnouncements visible={visibleAnnouncements} />
          </main>
          <div className="right-section">
            <div className="nav">
              <button className="btn" onClick={() => setSideVisible(true)}>
                <span className="material-icons-sharp">menu</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Test;
