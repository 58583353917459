import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
const BreadCrumb = ({ title, rootUrl, parentUrl, currentUrl }) => {
  return (
    <div className="breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
      <br />
      <div className="container animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <div className="row">
                <div className="section-title text-start col-6"></div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <div className="d-flex gap-3 flex-wrap">
                    <a
                      href="https://www.instagram.com/megagenamerica/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className=" text-end mb--20 ml--10 mr--10">
                        <FaInstagram
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    </a>
                    <a
                      href="https://www.facebook.com/MegagenAmerica/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className=" text-end mb--20 ml--10 mr--10">
                        <FaFacebook style={{ width: "50px", height: "50px" }} />
                      </div>
                    </a>
                    <a
                      href="https://www.youtube.com/@MegaGenTV"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className=" text-end mb--20 ml--10 mr--10">
                        <FaYoutube style={{ width: "50px", height: "50px" }} />
                      </div>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/megagenamerica"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className=" text-end mb--20 ml--10 mr--10">
                        <FaLinkedin style={{ width: "50px", height: "50px" }} />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default BreadCrumb;
