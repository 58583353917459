import React, { useState } from "react";
import emailjs from "emailjs-com";
import { API_URL2 } from "../../api/APIUrl";

// Need to make a query here to get the user associated with the email address provided.
// I would need a separate token for password reset... this would expire every 30 min when used.
// Each Token will be associated with a user

const PasswordResetForm = () => {
  // create a token associated with the user here on form submit.

  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [formState, setFormState] = useState({
    username: "",
  });
  let styleHidden = "d-none";
  if (visible) {
    styleHidden = "";
  } else {
    styleHidden = "d-none";
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  function createToken() {
    fetch(`${API_URL2}/api/users/changepw/nologin/${formState.username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setEmail(data.email);
        fetch(`${API_URL2}/api/users/changePW/token/${data._id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((r2) => r2.json())
          .then((data2) => {
            setToken(data2.token);
          });
      });
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // create token here
    // put hidden values as token and link
    createToken();
    document.getElementById("hidden-input-email").value = email;
    document.getElementById("hidden-input-code").value = token;
    document.getElementById("hidden-input-link").value =
      "https://sm-portal.megagenamerica.com/user-password-reset";
    emailjs
      .sendForm(
        "service_7kfdjoi",
        "template_fsmf85a",
        event.target,
        "-4nqcbxCVpvbcRiqP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    event.target.reset();
    setVisible(true);
  };
  return (
    <div className="login-form-box">
      <h3 className="mb-30">Reset Your Password</h3>
      <p className="mb-30">
        Please enter the username your account is associated with.
      </p>
      <form className="login-form" onSubmit={handleFormSubmit}>
        <input type="hidden" id="hidden-input-code" name="code" value=" " />
        <input type="hidden" id="hidden-input-link" name="link" value=" " />
        <input type="hidden" id="hidden-input-email" name="email" value=" " />
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="Username"
            name="username"
            id="username"
            required
            onChange={handleChange}
          />
        </div>
        <button className="rn-btn btn-default w-100 mb--30" type="submit">
          <span>Reset Password</span>
        </button>
        <p className={`results ${styleHidden}`} id="results">
          An email has been sent! Please check your inbox.
        </p>
      </form>
    </div>
  );
};

export default PasswordResetForm;
