import React, { useEffect, useState } from "react";
import BreadCrumbProf from "../component/breadcrumb/BreadcrumbProf";
import Layout from "../component/Layout";
import SectionTitle from "../component/sectionTitle/SectionTitle";
import Auth from "../utils/auth";
import GalleryItemProf from "../component/gallery/GalleryItemProf";
import GalleryItemVideoProf from "../component/gallery/GalleryItemVideoProf";
import { API_URL2 } from "../api/APIUrl";

function Profile() {
  const [username, setUsername] = useState("");
  const [downloaded, setDownloaded] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [yesChecked, setYesChecked] = useState(false);
  const [noChecked, setNoChecked] = useState(false);

  function getUser() {
    fetch(`${API_URL2}/api/users/${Auth.userId()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsername(data.username);
        setDownloaded(data.downloaded);

        if (data.subscribed === true || data.subscribed === "true") {
          setYesChecked(true);
        } else {
          setNoChecked(true);
        }
      });
  }
  useEffect(() => {
    getUser();
  }, []);

  const editsub = async (event) => {
    event.preventDefault();
    // Save the edited post
    fetch(`${API_URL2}/api/users/edit/${Auth.userId()}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscribed: "hello add the actual changed value here",
      }),
    }).then((r) => r.json());
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (password === passwordRepeat) {
      fetch(`${API_URL2}/api/users/${Auth.userId()}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, oldPassword }),
      })
        .then((r) => r.json())
        .then((data) => {
          if ("Could not find user." !== data.message) {
            alert("Successfully changed password.");
          } else {
            alert("Credentials Incorrect.");
          }
        });
    } else {
      alert("Passwords do not match.");
    }
    event.target.reset();
  };
  return (
    <>
      <Layout>
        <BreadCrumbProf username={username} />
        <div className="minec-contact-us contact-us-area  bg-color-white mt--50">
          <div className="container minec-animated-shape">
            <div className="row">
              <div className="text-end">
                {Auth.isAdmin() === "true" ? (
                  <>
                    <a href="/admin/data">
                      <button className="rn-btn btn-default mr--20">
                        <span>Analyze Data</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </button>
                    </a>
                    <a href="/add-post">
                      <button className="rn-btn btn-default">
                        <span>Add Post</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </button>
                    </a>
                  </>
                ) : (
                  <>
                    <h6>Email Announcements:</h6>
                    {yesChecked === true || yesChecked === "true" ? (
                      <button
                        className="rn-btn btn-default"
                        onClick={() => {
                          setYesChecked(false);
                          setNoChecked(true);
                        }}
                      >
                        <span>Unsubscribe</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </button>
                    ) : (
                      <button
                        className="rn-btn btn-default"
                        onClick={() => {
                          setYesChecked(true);
                          setNoChecked(false);
                        }}
                      >
                        <span>Subscribe</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="minec-contact-us contact-us-area section-gapBot bg-color-white">
          <div className="container minec-animated-shape">
            <div className="section-title text-start">
              <span className="pre-title">Edit Info</span>
              <h3 className="title">Change Password</h3>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <img
                  src="/images/others/lock.webp"
                  alt="lock"
                  style={{
                    borderRadius: "50px",
                    width: "430px",
                    height: "430px",
                  }}
                />
              </div>
              <div className="col-lg-6">
                <form
                  className="rnt-contact-form rwt-dynamic-form row"
                  onSubmit={handleFormSubmit}
                >
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="username"
                        placeholder="Username*"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        name="currentpw"
                        placeholder="Current Password*"
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        name="newpw"
                        placeholder="New Password*"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        name="newpwrepeat"
                        placeholder="Repeat New Password*"
                        onChange={(e) => setPasswordRepeat(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button className="rn-btn btn-default w-100" type="submit">
                      <span>Update</span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="inner mt_md--50 mt_sm--50 mt--70">
              <SectionTitle
                classes="text-start"
                slogan="History"
                title="Download History"
              />
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-12">
                <div className="minec-gallery-items row g-5 mt--5">
                  {downloaded.map((item) => {
                    if (item.fileExt === "mp4") {
                      return (
                        <div key={item.id} className="col-lg-4 col-md-6 col-12">
                          <GalleryItemVideoProf
                            key={item.imageId}
                            data={item}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={item.id} className="col-lg-4 col-md-6 col-12">
                          <GalleryItemProf key={item.imageId} data={item} />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </Layout>
    </>
  );
}

export default Profile;
