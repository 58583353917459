import React, { useEffect, useState } from "react";
import { API_URL2 } from "../../api/APIUrl";
import Modal from "react-modal";
const AdminUserList = ({ visible }) => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const itemsPerPage = 10;
  const finalIndex = currentPage * itemsPerPage;
  const startingIndex = finalIndex - itemsPerPage;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };
  const handleSave = async (event) => {
    event.preventDefault();
    // Save the edited post
    fetch(`${API_URL2}/api/users/edit/${editUser._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editUser),
    })
      .then((r) => r.json())
      .then((data) => {
        getAllUsers();
        closeModal();
      });
  };
  const openModal = (post) => {
    setEditUser(post);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditUser(null);
  };
  function getAllUsers() {
    fetch(`${API_URL2}/api/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsers(data);
      });
  }
  useEffect(() => {
    getAllUsers();
  }, []);
  const items = users.slice(startingIndex, finalIndex);
  const npage = Math.ceil(users.length / itemsPerPage);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  function getPageRange(current, total) {
    const range = [];

    if (total <= 6) {
      for (let i = 1; i <= total; i++) {
        range.push(i);
      }
    } else {
      range.push(1);
      if (current > 4) {
        range.push("...");
      }
      if (current > 3 && current < total - 2) {
        range.push(current - 1, current, current + 1);
      } else if (current <= 3) {
        range.push(2, 3, 4);
      } else {
        range.push(total - 3, total - 2, total - 1);
      }
      if (current < total - 3) {
        range.push("...");
      }
      range.push(total);
    }

    return range;
  }

  return (
    <div className="container text-center">
      <div
        className={`  recent-orders row justify-content-center mt--70 ${
          visible === false ? "d-none" : ""
        } `}
      >
        <h1 className="text-center">User List</h1>
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Country</th>
              <th>Email</th>
              <th>Subbed</th>
              <th>Action</th>
            </tr>
          </thead>

          {items.map((user, index) => (
            <tbody>
              <tr>
                <td data-label="Username">{user.username}</td>{" "}
                <td data-label="Country">{user.country}</td>
                <td data-label="Email">{user.email}</td>{" "}
                <td data-label="Subbed">
                  {user.subscribed === true ? "true" : "false"}
                </td>{" "}
                <td data-label="Action">
                  <button
                    className="btn-default btn-xsmall mr--5"
                    onClick={() => openModal(user)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <div className="pagination">
          <button
            className="btn-default btn-white btn-small"
            onClick={prePage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {getPageRange(currentPage, npage).map((page, index) =>
            page === "..." ? (
              <span key={index}>...</span>
            ) : (
              <button
                key={index}
                onClick={() => changeCPage(page)}
                className={`btn-default btn-white btn-small ${
                  currentPage === page ? "active" : ""
                }`}
                style={{ margin: "5px" }}
              >
                {page}
              </button>
            )
          )}
          <button
            className="btn-default btn-white btn-small"
            onClick={nextPage}
            disabled={currentPage === npage}
          >
            Next
          </button>
        </div>
      </div>{" "}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <h3>Edit Post</h3>
        {editUser && (
          <form
            className="rnt-contact-form rwt-dynamic-form row"
            onSubmit={handleSave}
          >
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="postTitle">Username:</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="username"
                  value={editUser.username}
                  placeholder="Username"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="postDesc">Country:</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="country"
                  value={editUser.country}
                  placeholder="Country"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="postDesc">Email:</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="email"
                  value={editUser.email}
                  placeholder="Email"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="subscribed">Subscribed:</label>
                <select
                  id="my-select"
                  name="subscribed"
                  onChange={handleInputChange}
                  required
                >
                  {editUser.subscribed === false ? (
                    <>
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </>
                  ) : (
                    <>
                      <option value={true}>true</option>{" "}
                      <option value={false}>false</option>
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className="col-lg-12">
              <button className="rn-btn btn-default w--50 mr--5" type="submit">
                <span>Update</span>
                <i className="icon-arrow-right-line-right"></i>
              </button>
              <button
                className="rn-btn btn-default w--50 ml--5"
                type="button"
                onClick={closeModal}
              >
                <span>Cancel</span>
                <i className="icon-arrow-right-line-right"></i>
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default AdminUserList;
