import React, { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import Auth from "../../utils/auth";
import { FaDownload } from "react-icons/fa";
import { API_URL2 } from "../../api/APIUrl";

const GalleryItem = ({ data }) => {
  const [toggler, setToggler] = useState(false);
  const [disable, setDisable] = useState(false);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    if (Auth.isAdmin() === "false") {
      setVisible("d-none");
      setDisable(true);
    }
  }, []);
  const download = async () => {
    const res = await fetch(`${API_URL2}/api/posts/${data._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ downloadCount: data.downloadCount + 1 }),
    });

    const res2 = await fetch(
      `${API_URL2}/api/users/download/${Auth.userId()}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          downloaded: {
            _id: data._id,
            imageId: data.imageId,
            fileId: data.fileId,
            fileExt: data.fileExt,
            thumbnail: data.thumbnail,
            postTitle: data.postTitle,
            postDesc: data.postDesc,
            category: data.category,
            countryOfOrigin: data.countryOfOrigin,
          },
        }),
      }
    );
    res.json();
    res2.json();
  };

  const deletePost = async (id) => {
    await fetch(`${API_URL2}/api/posts/${id}`, {
      method: "DELETE",
    });

    window.location.reload();
  };
  return (
    <div className="gallery-grid-item grid-metro-item cat--1 cat--3">
      <h6
        className="title"
        style={{
          textAlign: "left",
          color: "white",
          position: "absolute",
          zIndex: 1,
          background: "#059DFF",
          borderRadius: "6px",
          margin: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        <FaDownload style={{ padding: 0, margin: 0, marginRight: "10px" }} />{" "}
        {data.downloadCount}
      </h6>
      <div className="gallery-grid">
        <div
          className="inner"
          style={{
            background: "#f0f0f0",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <div className="thumbnail text-center">
            <img
              className=""
              style={{ height: "365px" }}
              src={data.thumbnail}
              alt={data.postDesc}
            />
          </div>
        </div>

        <button className="zoom-icon" onClick={() => setToggler(!toggler)}>
          <i className="icon-zoom-in-line"></i>
          <br />
          <br />
          {/* replace the data.image below for the actual link i fetch from s3 and make it count towards users download count*/}
        </button>

        <FsLightbox toggler={toggler} sources={[data.thumbnail]} />

        <div className="hover-action">
          <div className="hover-content ">
            <div className="hover-text ">
              <a
                className="mr--5"
                href={data.fileId}
                target="_blank"
                rel="noreferrer"
                download
              >
                <button
                  className="rn-btn btn-default btn-medium text-center btn-secondary "
                  onClick={download}
                >
                  Download
                </button>
              </a>

              <button
                className={`rn-btn btn-default btn-medium text-center btn-shade ${visible}`}
                onClick={() => deletePost(data._id)}
                disabled={disable}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div
          className="mt--5"
          style={{
            background: "#f0f0f0",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <h6
            className="title ml--5 mr--5 mt--10"
            style={{
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            {data.postTitle}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default GalleryItem;
