import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";

const MobileMenu = ({ show, onClose }) => {
  var elements = document.querySelectorAll(".popup-mobile-menu .dropdown > a");
  var elementsTwo = document.querySelectorAll(
    ".popup-mobile-menu .with-megamenu > a"
  );
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (var j in elementsTwo) {
    if (elementsTwo.hasOwnProperty(i)) {
      elementsTwo[j].onclick = function () {
        this.parentElement
          .querySelector(".rn-megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <>
      <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
        <div className="inner">
          <div className="header-top">
            <div className="logo">
              <Link to={process.env.PUBLIC_URL + "/"}>
                <img
                  src="/images/logo/MEGAGEN_LOGO_black.webp"
                  alt="minec logo"
                />
              </Link>
            </div>
            <div className="close-menu">
              <button className="close-button" onClick={onClose}>
                <i className="ri-close-line"></i>
              </button>
            </div>
          </div>
          <Nav />
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
