import React, { useState } from "react";
import Auth from "../../utils/auth";

const AdminSideBar = ({
  VisibilityCard,
  VisibilityUserList,
  VisibilityPostList,
  VisibilityChangePw,
  VisibilityAnalytics,
  VisibilityAnnouncements,
  VisibilitySide,
  VisibilityClose,
}) => {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);

  return (
    <>
      <aside className={`${VisibilitySide === true ? "d-block" : ""}`}>
        <div className="toggle">
          <a href="/">
            <div className="logo">
              <img src="images/logo/MEGAGEN_LOGO_black.webp" />
            </div>
          </a>
          <div className="close ml--40" id="close-btn">
            <span
              className="material-icons-sharp"
              onClick={() => VisibilityClose(false)}
            >
              close
            </span>
          </div>
        </div>

        <div className="sidebar">
          <a
            className={active1 === true ? "active" : ""}
            onClick={() => {
              VisibilityCard(true);
              VisibilityChangePw(true);
              VisibilityPostList(false);
              VisibilityUserList(false);
              VisibilityAnalytics(false);
              VisibilityAnnouncements(false);
              setActive1(true);
              setActive2(false);
              setActive3(false);
              setActive4(false);
              setActive5(false);
            }}
          >
            <span className="material-icons-sharp">dashboard</span>
            <h3>Dashboard</h3>
          </a>
          <a
            className={active2 === true ? "active" : ""}
            onClick={() => {
              VisibilityCard(false);
              VisibilityChangePw(false);
              VisibilityPostList(false);
              VisibilityUserList(true);
              VisibilityAnalytics(false);
              VisibilityAnnouncements(false);
              setActive1(false);
              setActive2(true);
              setActive3(false);
              setActive4(false);
              setActive5(false);
            }}
          >
            <span className="material-icons-sharp">person_outline</span>
            <h3>Users</h3>
          </a>
          <a
            className={active3 === true ? "active" : ""}
            onClick={() => {
              VisibilityCard(false);
              VisibilityChangePw(false);
              VisibilityPostList(true);
              VisibilityUserList(false);
              VisibilityAnalytics(false);
              VisibilityAnnouncements(false);
              setActive1(false);
              setActive2(false);
              setActive3(true);
              setActive4(false);
              setActive5(false);
            }}
          >
            <span className="material-icons-sharp">article</span>
            <h3>Posts</h3>
          </a>
          <a
            className={active4 === true ? "active" : ""}
            onClick={() => {
              VisibilityCard(false);
              VisibilityChangePw(false);
              VisibilityPostList(false);
              VisibilityUserList(false);
              VisibilityAnalytics(true);
              VisibilityAnnouncements(false);
              setActive1(false);
              setActive2(false);
              setActive3(false);
              setActive4(true);
              setActive5(false);
            }}
          >
            <span className="material-icons-sharp ">insights</span>
            <h3>Analytics</h3>
          </a>
          <a
            className={active5 === true ? "active" : ""}
            onClick={() => {
              VisibilityCard(false);
              VisibilityChangePw(false);
              VisibilityPostList(false);
              VisibilityUserList(false);
              VisibilityAnalytics(false);
              VisibilityAnnouncements(true);
              setActive1(false);
              setActive2(false);
              setActive3(false);
              setActive4(false);
              setActive5(true);
            }}
          >
            <span className="material-icons-sharp">campaign</span>
            <h3>Announce</h3>
          </a>{" "}
          <a className="home-link" href="/">
            <span className="material-icons-sharp">home</span>
            <h3>Back Home</h3>
          </a>
          <a onClick={() => Auth.logout()}>
            <span className="material-icons-sharp">logout</span>
            <h3>Logout</h3>
          </a>
        </div>
      </aside>
    </>
  );
};

export default AdminSideBar;
