import React from "react";

const BreadCrumbTerms = ({ title, rootUrl, parentUrl, currentUrl }) => {
  return (
    <div className="breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
      <div className="container animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <div className="row">
                <div className="section-title text-start col-6"></div>
                <div className="col-6">
                  <div className="breadcrumb-inner text-start">
                    <div className="section-title text-start">
                      <span className="pre-title">TOS</span>
                      <h4 className="title">Terms Of Service</h4>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbTerms;
