import React, { useEffect, useState } from "react";
import BreadCrumb from "../component/breadcrumb/Breadcrumb";
import Layout from "../component/Layout";
import { Link } from "react-router-dom";
import { API_URL2 } from "../api/APIUrl";
import moment from "moment";
//bring in state functions to increase and decrease number for the red notification here
// then take the unix date from each visible item and just add or subtract

const Announcements = ({ increaseCount, decreaseCount, count }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const currentDate = Date.now();
  // const currentDateUTC = currentDate.toUTCString();

  const itemsPerPage = 3;
  useEffect(() => {
    getAnnouncements();
  }, []);
  const filterData = [
    { text: "View All" },
    { text: "Content Update" },
    { text: "News" },
    { text: "Analysis" },
    { text: "Bug Fixes" },
  ];

  const finalIndex = currentPage * itemsPerPage;
  const startingIndex = finalIndex - itemsPerPage;
  const updateCount = (items) => {
    for (let i = 0; i < items.length - 1; i++) {
      if (currentDate - moment(items.reverse()[i].unixDate).unix() < 86400) {
        console.log(currentDate - moment(items.reverse()[i].unixDate));
        increaseCount();
      }
      if (currentDate - moment(items.reverse()[i].unixDate).unix() > 86400) {
        console.log(currentDate - moment(items.reverse()[i].unixDate));

        decreaseCount();
      }
    }
  };
  const getAnnouncements = async () => {
    const res = await fetch(`${API_URL2}/api/announcements`);
    const res2 = await res.json();
    setAnnouncements(res2);
    setVisibleItems(res2.reverse());
  };
  useEffect(() => {
    setActiveFilter(filterData[0].text.toLowerCase());
    // console.log(count);
  }, []);
  useEffect(() => {
    updateCount(announcements);
    // console.log(count);
  }, [announcements]);
  //use this function wwith moment to convert the date from database to unix time

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (
      e.target.textContent.toLowerCase() === filterData[0].text.toLowerCase()
    ) {
      tempData = announcements;
    } else {
      tempData = announcements.filter(
        (data) => data.category === e.target.textContent
      );
    }
    setVisibleItems(tempData);
  };

  const items = visibleItems.slice(startingIndex, finalIndex);
  const npage = Math.ceil(visibleItems.length / itemsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function getPageRange(current, total) {
    const range = [];

    if (total <= 6) {
      for (let i = 1; i <= total; i++) {
        range.push(i);
      }
    } else {
      range.push(1);
      if (current > 4) {
        range.push("...");
      }
      if (current > 3 && current < total - 2) {
        range.push(current - 1, current, current + 1);
      } else if (current <= 3) {
        range.push(2, 3, 4);
      } else {
        range.push(total - 3, total - 2, total - 1);
      }
      if (current < total - 3) {
        range.push("...");
      }
      range.push(total);
    }

    return range;
  }
  return (
    <>
      <Layout>
        <BreadCrumb />
        <div className="section-title text-center mt--60">
          <span className="pre-title">Updates</span>
          <h4 className="title">Announcements</h4>
        </div>
        <div className="elements-area section-gapBottom mt--40 bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="button-group isotop-filter filters-button-group text-center">
                  {filterData.map((filter) => (
                    <button
                      onClick={handleChange}
                      key={filter.id}
                      className={
                        filter.text.toLowerCase() === activeFilter
                          ? "is-checked"
                          : " "
                      }
                    >
                      {filter.text}
                    </button>
                  ))}
                </div>
                <br />
              </div>
              <div className="col-lg-12">
                <div className="row g-5">
                  {items.reverse().map((data) => (
                    <div className=" col-12" key={data.id}>
                      <div className={`blog blog-type-2 radius-small `}>
                        <div className="inner">
                          <div className="row">
                            <div
                              className="thumbnail col-2"
                              style={{ alignContent: "center" }}
                            >
                              <a className="text-center">
                                <span
                                  className="material-icons-sharp"
                                  style={{ fontSize: "100px" }}
                                >
                                  campaign
                                </span>
                              </a>
                            </div>
                            <div className="content col-10">
                              <h5 className="title">
                                <a>{data.text}</a>
                              </h5>
                              <div className="blog-card-bottom">
                                <ul className="blog-meta">
                                  <li>
                                    <i className="icon-calendar-2-line"></i>
                                    {data.date}
                                  </li>
                                  <li>{data.category}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pagination mt--30 mb--50">
                <button
                  className="btn-default btn-white btn-small"
                  onClick={prePage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {getPageRange(currentPage, npage).map((page, index) =>
                  page === "..." ? (
                    <span key={index}>...</span>
                  ) : (
                    <button
                      key={index}
                      onClick={() => changeCPage(page)}
                      className={`btn-default btn-white btn-small ${
                        currentPage === page ? "active" : ""
                      }`}
                      style={{ margin: "5px" }}
                    >
                      {page}
                    </button>
                  )
                )}
                <button
                  className="btn-default btn-white btn-small"
                  onClick={nextPage}
                  disabled={currentPage === npage}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Announcements;
