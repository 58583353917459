import React, { useState, useEffect } from "react";
import { API_URL2 } from "../../api/APIUrl";
import Auth from "../../utils/auth";
const AdminChangePW = ({ visible }) => {
  const [username, setUsername] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  function getUser() {
    fetch(`${API_URL2}/api/users/${Auth.userId()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsername(data.username);
      });
  }
  useEffect(() => {
    getUser();
  }, []);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (password === passwordRepeat) {
      fetch(`${API_URL2}/api/users/${Auth.userId()}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, oldPassword }),
      })
        .then((r) => r.json())
        .then((data) => {
          if ("Could not find user." !== data.message) {
            alert("Successfully changed password.");
          } else {
            alert("Credentials Incorrect.");
          }
        });
    } else {
      alert("Passwords do not match.");
    }
    event.target.reset();
  };
  return (
    <div
      className={`container ${visible === false ? "d-none" : ""}`}
      style={{ backgroundColor: "white", borderRadius: "10px" }}
    >
      <div className="row g-5 mt--100">
        <div className="section-title text-start col-lg-6">
          <span className="pre-title">Edit Info</span>
          <h3 className="title">Change Password</h3>
        </div>
        <div className="col-lg-6 pt--20 pb--20">
          <form
            className="rnt-contact-form rwt-dynamic-form row"
            onSubmit={handleFormSubmit}
          >
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="username"
                  value={username}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  name="currentpw"
                  placeholder="Current Password*"
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  name="newpw"
                  placeholder="New Password*"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  name="newpwrepeat"
                  placeholder="Repeat New Password*"
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12">
              <button className="rn-btn btn-default w-100" type="submit">
                <span>Update</span>
                <i className="icon-arrow-right-line-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminChangePW;
