import React from "react";

const BreadCrumb = ({ username }) => {
  return (
    <div className="breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
      <div className="container animated-shape">
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <div className="breadcrumb-inner text-start">
              <div className="section-title text-start">
                <span className="pre-title">Welcome Back</span>
                <h4 className="title">{username}'s Dashboard</h4>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
