import React, { useState, useEffect } from "react";
import { API_URL2 } from "../../api/APIUrl";
import Auth from "../../utils/auth";
const AdminUsers = ({ visible }) => {
  const [users, setUsers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [username, setUsername] = useState("");

  const getTotalDownloadCount = () => {
    return users.reduce((total, user) => total + user.downloadCount, 0);
  };
  function getAllUsers() {
    fetch(`${API_URL2}/api/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsers(data);
      });
  }
  function getUser() {
    fetch(`${API_URL2}/api/users/${Auth.userId()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsername(data.username);
      });
  }
  function getAllPosts() {
    fetch(`${API_URL2}/api/posts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setPosts(data.reverse());
      });
  }

  useEffect(() => {
    getAllUsers();
    getAllPosts();
    getUser();
  }, []);
  return (
    <div
      className={`analyse mt--60 row justify-content-center ${
        visible === false ? "d-none" : ""
      }`}
    >
      {" "}
      <h1 className="text-center">Welcome Back {username}!</h1>
      <div className="sales col-lg-3 mr--10 ml--10">
        <div className="status">
          <div className="info text-center">
            <h2 style={{ fontSize: "18px" }}>Total Downloads By Users</h2>
            <h1>{getTotalDownloadCount()}</h1>
          </div>
        </div>
      </div>
      <div className="visits col-lg-3 mr--10 ml--10">
        <div className="status">
          <div className="info text-center">
            <h2 style={{ fontSize: "18px" }}>Total Users</h2>
            <h1>{users.length}</h1>
          </div>
        </div>
      </div>
      <div className="searches col-lg-3 mr--10 ml--10">
        <div className="status">
          <div className="info text-center">
            <h2 style={{ fontSize: "18px" }}>Total Posts</h2>
            <h1>{posts.length}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
