import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

const GalleryItemVideoProf = ({ data }) => {
  const [toggler, setToggler] = useState(false);

  return (
    <div className="gallery-grid-item grid-metro-item cat--1 cat--3">
      <div className="gallery-grid">
        <div
          className="inner"
          style={{
            background: "#f0f0f0",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <video width="357" height="361" muted>
            <source src={data.fileId} type="video/mp4" />
          </video>
        </div>

        <button className="zoom-icon" onClick={() => setToggler(!toggler)}>
          <i className="icon-zoom-in-line"></i>
          <br />
          <br />
          {/* replace the data.image below for the actual link i fetch from s3 and make it count towards users download count*/}
        </button>

        <FsLightbox toggler={toggler} sources={[data.fileId]} />

        <div
          className="mt--5"
          style={{ background: "#f0f0f0", padding: "5px", borderRadius: "5px" }}
        >
          <h6
            className="title ml--5 mr--5 mt--10"
            style={{
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            {data.postTitle}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default GalleryItemVideoProf;
