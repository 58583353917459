import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL2 } from "../api/APIUrl";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function login() {
    fetch(`${API_URL2}/api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((r) => r.json())
      .then((r) => {
        if ("success" === r.message) {
          localStorage.setItem("user", JSON.stringify({ username }));
          localStorage.setItem("id_token", JSON.stringify({ token: r.token }));
          localStorage.setItem("admin", r.user.isAdmin);
          localStorage.setItem("userid", r.user._id);
          localStorage.setItem("country", r.user.country);

          window.location.reload("/");
        } else {
          window.alert("Wrong user credentials");
        }
      });
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      login();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="login-register-page-wrapper  bg-color-white">
        <video autoPlay loop muted>
          <source
            src="https://mgg-sm-portal.s3.us-east-2.amazonaws.com/bg1.mp4"
            type="video/mp4"
          ></source>
          {/* <source
            src="https://mgg-sm-portal.s3.us-east-2.amazonaws.com/bg2.mp4"
            type="video/mp4"
          ></source> */}
          {/* <source
            src="https://mgg-sm-portal.s3.us-east-2.amazonaws.com/bg3.mp4"
            type="video/mp4"
          ></source> */}
          {/* <source
            src="https://mgg-sm-portal.s3.us-east-2.amazonaws.com/bg4.mp4"
            type="video/mp4"
          ></source> */}
        </video>
        <div className=" checkout-page-style section-gap pl--50 pr--50">
          <h3 className="mb--90 text-center"></h3>
          <div className="row g-5 text-center">
            <div className="col-lg-9"></div>
            <div className="col-lg-3 ">
              <div className="login-form-box" style={{ background: "white" }}>
                <h3 className="mb-30"> MegaGen Cloud</h3>
                <form className="login-form" onSubmit={handleFormSubmit}>
                  <div className="input-box mb--30">
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      id="username"
                      onChange={(e) =>
                        setUsername(e.target.value.toLowerCase())
                      }
                    />
                  </div>
                  <div className="input-box mb--30">
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button
                    className="rn-btn btn-default w-100 mb--30"
                    type="submit"
                  >
                    <span> Log In</span>
                  </button>
                  <div className="input-box">
                    <Link to="/password-reset" className="lost-password">
                      Forgot password?
                    </Link>
                  </div>

                  {/* {error ? <p>The login information is not correct</p> : null} */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
