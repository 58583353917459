import React, { useEffect, useState } from "react";
import { API_URL2 } from "../../api/APIUrl";
import FsLightbox from "fslightbox-react";
import Modal from "react-modal";

const AdminPostList = ({ visible }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [togglerArray, setTogglerArray] = useState(
    Array(posts.length).fill(false)
  );
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editPost, setEditPost] = useState(null);

  const toggleLightbox = (index) => {
    const newTogglerArray = [...togglerArray];
    newTogglerArray[index] = !newTogglerArray[index];
    setTogglerArray(newTogglerArray);
  };

  const openModal = (post) => {
    setEditPost(post);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditPost(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditPost({ ...editPost, [name]: value });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    // Save the edited post
    fetch(`${API_URL2}/api/posts/${editPost._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editPost),
    })
      .then((r) => r.json())
      .then((data) => {
        getAllPosts();
        closeModal();
      });
  };

  const itemsPerPage = 10;
  const finalIndex = currentPage * itemsPerPage;
  const startingIndex = finalIndex - itemsPerPage;

  function getAllPosts() {
    fetch(`${API_URL2}/api/posts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setPosts(data.reverse());
      });
  }

  useEffect(() => {
    getAllPosts();
  }, []);

  const items = posts.slice(startingIndex, finalIndex);
  const npage = Math.ceil(posts.length / itemsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function getPageRange(current, total) {
    const range = [];

    if (total <= 6) {
      for (let i = 1; i <= total; i++) {
        range.push(i);
      }
    } else {
      range.push(1);
      if (current > 4) {
        range.push("...");
      }
      if (current > 3 && current < total - 2) {
        range.push(current - 1, current, current + 1);
      } else if (current <= 3) {
        range.push(2, 3, 4);
      } else {
        range.push(total - 3, total - 2, total - 1);
      }
      if (current < total - 3) {
        range.push("...");
      }
      range.push(total);
    }

    return range;
  }

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...posts];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems.slice(startingIndex, finalIndex);
  }, [posts, sortConfig, startingIndex, finalIndex]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="container text-center">
      <div
        className={`recent-orders row justify-content-center mt--70 ${
          visible === false ? "d-none" : ""
        } `}
      >
        <div className="container">
          <div className="text-end">
            <a href="/add-post" style={{ display: "inline-block" }}>
              <button className="rn-btn btn-default btn-medium">
                <span>Add Post</span>
                <i className="icon-arrow-right-line-right"></i>
              </button>
            </a>
          </div>
          <h1 className="text-center">Post List</h1>
        </div>
        <table>
          <thead>
            <tr>
              {" "}
              <th onClick={() => requestSort("postTitle")}>Post Title</th>
              <th onClick={() => requestSort("category")}>Category</th>
              <th onClick={() => requestSort("downloadCount")}>
                Post Description
              </th>
              <th>Action</th>
            </tr>
          </thead>

          {sortedItems.map((post, index) => (
            <tbody key={post.id}>
              <tr>
                <td data-label="Post Title">{post.postTitle}</td>
                <td data-label="Category">{post.category}</td>
                <td data-label="Post Description">{post.postDesc}</td>
                <td data-label="Action">
                  <button
                    className="btn-default btn-xsmall mr--5"
                    onClick={() => toggleLightbox(index)}
                  >
                    View
                  </button>
                  <button
                    className="btn-default btn-xsmall mr--5"
                    onClick={() => openModal(post)}
                  >
                    Edit
                  </button>
                  <FsLightbox
                    toggler={togglerArray[index]}
                    sources={[post.thumbnail]}
                  />
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <div className="pagination">
          <button
            className="btn-default btn-white btn-small"
            onClick={prePage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {getPageRange(currentPage, npage).map((page, index) =>
            page === "..." ? (
              <span key={index}>...</span>
            ) : (
              <button
                key={index}
                onClick={() => changeCPage(page)}
                className={`btn-default btn-white btn-small ${
                  currentPage === page ? "active" : ""
                }`}
                style={{ margin: "5px" }}
              >
                {page}
              </button>
            )
          )}
          <button
            className="btn-default btn-white btn-small"
            onClick={nextPage}
            disabled={currentPage === npage}
          >
            Next
          </button>
        </div>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <h3>Edit Post</h3>
          {editPost && (
            <form
              className="rnt-contact-form rwt-dynamic-form row"
              onSubmit={handleSave}
            >
              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="postTitle">Title:</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="postTitle"
                    value={editPost.postTitle}
                    placeholder="Post Title*"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="postDesc">Description:</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="postDesc"
                    value={editPost.postDesc}
                    placeholder="Post Description* (ie. AnyRidge, packaging, reel, etc)"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="category">Category:</label>
                  <select
                    name="category"
                    id="my-select"
                    value={editPost.category}
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Please choose a category*</option>
                    <option value="product post">Product Post</option>
                    <option value="product reel">Product Reel</option>
                    <option value="company branding">Company Branding</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-12">
                <button
                  className="rn-btn btn-default w--50 mr--5"
                  type="submit"
                >
                  <span>Update</span>
                  <i className="icon-arrow-right-line-right"></i>
                </button>
                <button
                  className="rn-btn btn-default w--50 ml--5"
                  type="button"
                  onClick={closeModal}
                >
                  <span>Cancel</span>
                  <i className="icon-arrow-right-line-right"></i>
                </button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AdminPostList;
