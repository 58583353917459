import React, { useState } from "react";
import Layout from "../component/Layout";
import BreadCrumbPost from "../component/breadcrumb/BreadcrumbPost";
import { BsFiletypeMp4, BsFiletypeJpg, BsFiletypePng } from "react-icons/bs";
import { PiFileZip } from "react-icons/pi";
import MP4Form from "../component/forms/MP4Form";
import PNGForm from "../component/forms/PNGForm";
import JPGForm from "../component/forms/JPGForm";
import ZIPForm from "../component/forms/ZIPForm";
function CreatePost() {
  const [visibility, setVisibility] = useState(true);
  const [visibilityMP4Form, setVisibilityMP4Form] = useState(false);
  const [visibilityJPGForm, setVisibilityJPGForm] = useState(false);
  const [visibilityPNGForm, setVisibilityPNGForm] = useState(false);
  const [visibilityZIPForm, setVisibilityZIPForm] = useState(false);

  return (
    <>
      <Layout>
        <BreadCrumbPost />
        <div
          className={`container minec-animated-shape mt--20 ${
            visibility === false ? "" : "d-none"
          }`}
        >
          <div className="text-end">
            <button
              className="rn-btn btn-default"
              onClick={() => {
                setVisibilityJPGForm(false);
                setVisibilityMP4Form(false);
                setVisibilityPNGForm(false);
                setVisibilityZIPForm(false);
                setVisibility(true);
              }}
            >
              <span>Back</span>
              <i className="icon-arrow-right-line-right"></i>
            </button>
          </div>{" "}
          <div className="section-title text-center mt--40">
            <span className="pre-title">Upload Post</span>
            <h4 className="title">Fill Out All Information</h4>
            <p class="description">
              Please make the post description as detailed as possible for
              better search bar experience.
            </p>
          </div>
        </div>
        <div
          className={`minec-about-us-one-service service-area bg-color-white service-bg-position ${
            visibility === true ? "" : "d-none"
          }`}
        >
          <div className="container minec-animated-shape">
            <div className="row">
              <div className="section-title text-center mt--40">
                <span className="pre-title">File Type</span>
                <h4 className="title mb--30">
                  Which File Type Are You Uploading?
                </h4>
              </div>
              <div
                className="service-card service-card-3 col-lg-6 col-md-12 col-sm-12 mb--20"
                style={{ background: "#19BDF0" }}
                onClick={() => {
                  setVisibility(false);
                  setVisibilityMP4Form(true);
                }}
              >
                <div className="inner">
                  <div className="content">
                    <BsFiletypeMp4
                      style={{
                        width: "50px",
                        height: "50px",
                        color: "white",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="service-card service-card-3 col-lg-6 col-md-12 col-sm-12 mb--20"
                style={{ background: "#EB65A6" }}
                onClick={() => {
                  setVisibility(false);
                  setVisibilityJPGForm(true);
                }}
              >
                <div className="inner">
                  <BsFiletypeJpg
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "white",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  />
                </div>
              </div>

              <div
                className="service-card service-card-3 col-lg-6 col-md-12 col-sm-12 mb--20"
                style={{ background: "#FFDC01" }}
                onClick={() => {
                  setVisibility(false);
                  setVisibilityPNGForm(true);
                }}
              >
                <div className="inner">
                  <BsFiletypePng
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "white",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  />
                </div>
              </div>
              <div
                className="service-card service-card-3 col-lg-6 col-md-12 col-sm-12 mb--20"
                style={{ background: "#71BF44" }}
                onClick={() => {
                  setVisibility(false);
                  setVisibilityZIPForm(true);
                }}
              >
                <div className="inner">
                  <PiFileZip
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "white",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className={` ${visibilityMP4Form === true ? "" : "d-none"}`}>
          <MP4Form />
        </div>
        <div className={` ${visibilityPNGForm === true ? "" : "d-none"}`}>
          <PNGForm />
        </div>
        <div className={` ${visibilityJPGForm === true ? "" : "d-none"}`}>
          <JPGForm />
        </div>{" "}
        <div className={` ${visibilityZIPForm === true ? "" : "d-none"}`}>
          <ZIPForm />
        </div>
        <br />
        <br />
      </Layout>
    </>
  );
}

export default CreatePost;
