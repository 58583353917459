import React from "react";
import Header from "./header/Header";

const Layout = ({ children }) => {
  return (
    <>
      <main className="main-wrapper">
        <Header />
        {children}
      </main>
    </>
  );
};
export default Layout;
