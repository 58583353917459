import React from "react";
import Layout from "../component/Layout";
import BreadCrumbHelp from "../component/breadcrumb/BreadcrumbHelp";
import { Element } from "react-scroll";
import ContactUsForm from "../component/contact/ContactUsForm";

const Guidelines = () => {
  return (
    <>
      <Layout>
        <BreadCrumbHelp />
        <Element
          name="faq"
          className="landing-demo-faq-wrapper accordion-area accordion-shape-1 section-gap bg-color-white"
        >
          <div className="minec-contact-us contact-us-area bg-color-white">
            <div className="container minec-animated-shape">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <span className="pre-title">
                      Having Issues or Questions?
                    </span>
                    <h3 className="title">Email Us Or Use The Form</h3>
                  </div>
                </div>
              </div>
              <div className="row g-5 mt--20">
                <div className="col-lg-6">
                  <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                    <div className="row g-5">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="contact-address-card-1 email">
                          <div className="inner">
                            <div className="icon">
                              <i className="icon-mail-open-line"></i>
                            </div>
                            <div className="content">
                              <h6 className="title">Email Us</h6>
                              <p>
                                <a href="mailto:daniel.hong@megagenamerica.com">
                                  daniel.hong@megagenamerica.com
                                </a>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                </div>
              </div>
            </div>
          </div>
        </Element>
      </Layout>
    </>
  );
};

export default Guidelines;
