import React from "react";

const BreadCrumb = ({ title, rootUrl, parentUrl, currentUrl }) => {
  return (
    <div className="breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
      <div className="container animated-shape">
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <div className="breadcrumb-inner text-start">
              <div className="section-title text-start">
                <span className="pre-title">Upload</span>
                <h3 className="page-title">Create Post</h3>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
