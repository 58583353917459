import React, { useEffect, useState } from "react";
import { API_URL2 } from "../../api/APIUrl";
import { TailSpin } from "react-loader-spinner";
import emailjs from "emailjs-com";

const AdminAnnouncements = ({ visible }) => {
  const [text, setText] = useState("");
  const [users, setUsers] = useState("");
  const subscribedUser = [];
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  function getAllUsers() {
    fetch(`${API_URL2}/api/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsers(data);
        data.map((item) => {
          if (item.subscribed === true) {
            subscribedUser.push(item.email);
          }
        });
      });
  }
  useEffect(() => {
    getAllUsers();
  }, []);
  const handleformsubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = { text: text, category: category };

    await fetch(`${API_URL2}/api/announcements/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    emailjs
      .sendForm(
        "service_kgqweeo",
        "template_cwu4zus",
        event.target,
        "yksodf4DYcGzFg1QA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    // need to add emailjs here... but how do i make it take in date from this form input?????
    //so the event thing just actually takes the values from the names thing
    //should be fine to just use it except instead of e.target do event.target
    //cause that is what is being passed into the handle formsubmit function.
    // need to add hidden input of all email addresses that are subscribed? yes i neeed to
    event.target.reset();
    setLoading(false);
    alert("Successfully Added Post.");
  };
  return (
    <>
      {loading === false ? (
        <div
          className={`minec-contact-us contact-us-area section-gap ${
            visible === false ? "d-none" : ""
          } `}
        >
          <h1 className="text-center">Create Announcements</h1>
          <div className="container minec-animated-shape">
            <div className="row g-5 mt--20">
              <div className="col-lg-12">
                <form
                  className="rnt-contact-form rwt-dynamic-form row"
                  onSubmit={handleformsubmit}
                >
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input
                          name="subbedEmail"
                          hidden
                          value={subscribedUser}
                        ></input>
                        <label htmlFor="text" style={{ fontSize: "15px" }}>
                          Announcement Text:
                        </label>
                        <textarea
                          style={{
                            border: "gray 1px solid",
                            resize: "vertical",
                            textWrap: "wrap",
                          }}
                          type="text"
                          className="textarea1 form-control form-control-lg bg-white"
                          name="text"
                          placeholder="Text*"
                          onChange={(e) => setText(e.target.value)}
                          required
                        />
                      </div>{" "}
                      <div className="form-group col-lg-6">
                        <label htmlFor="text" style={{ fontSize: "15px" }}>
                          Category:
                        </label>
                        <select
                          name="category"
                          className="bg-white"
                          id="my-select"
                          style={{
                            border: "gray 1px solid",
                            borderRadius: "5px",
                          }}
                          required
                          onChange={(e) => {
                            setCategory(e.target.value);
                          }}
                        >
                          <option value="">Please choose a category*</option>
                          <option value="Content Update">Content Update</option>
                          <option value="News">News</option>
                          <option value="Bug Fixes">Bug Fixes</option>
                          <option value="Analysis">Analysis</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <button className="rn-btn btn-default w-100" type="submit">
                      <span>Create</span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="minec-contact-us contact-us-area section-gap mt--100">
          <div className="container minec-animated-shape text-center">
            <TailSpin color="#059DFF" />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminAnnouncements;
