import React from "react";
import Layout from "../component/Layout";
import BreadCrumbTerms from "../component/breadcrumb/BreadcrumbTerms";

const ToC = () => {
  return (
    <Layout>
      <BreadCrumbTerms />
      <div className="privacy-policy-area privacy-policy section-gap ">
        <div className="container">
          <div className="row g-5">
            <div className="content">
              <h4 className="title text-center">Acceptance of Terms</h4>
              <p>
                Welcome to MegaGen America’s Cloud (“we,” “our,” “us”). MegaGen
                America creates and shares content to support marketing
                activities and enhance MegaGen branding worldwide. You are free
                to download and use the content from our cloud platform for your
                uploads and marketing efforts under the following Terms and
                Conditions. These Terms and Conditions (“Terms”) govern your use
                of our cloud platform (“Platform”) that allows distributors
                around the world to download our image and video files
                (“Content”). By accessing or using our Platform, you agree to
                comply with and be bound by these Terms.
              </p>
              <h5 className="title">1. Use of the Platform</h5>
              <p>
                <strong>1.1 License:</strong> We grant you a limited,
                non-exclusive, non-transferable license to access and use the
                Platform for the purpose of downloading Content for distribution
                purposes only, subject to these Terms.
              </p>
              <h5 className="title">2. Prohibited Activities</h5>
              <p>
                <strong>
                  {" "}
                  2.1 Unauthorized Modification, Reproduction, and Sale:
                </strong>{" "}
                You are strictly prohibited from modifying, reproducing, or
                selling any Content downloaded from our Platform without our
                prior written consent.
              </p>{" "}
              <p>
                <strong>
                  {" "}
                  2.2 Other Prohibited Activities: You agree not to:
                </strong>{" "}
                Use the Platform for any illegal or unauthorized purpose.
                Interfere with or disrupt the Platform or servers or networks
                connected to the Platform. Attempt to gain unauthorized access
                to any part of the Platform or its related systems or networks.
              </p>
              <h5 className="title">3. Intellectual Property </h5>
              <p>
                <strong>3.1 Ownership:</strong> All Content available on the
                Platform is the property of MegaGen America or its licensors and
                is protected by copyright, trademark, and other intellectual
                property laws.
              </p>{" "}
              <p>
                <strong>3.2 Trademarks: </strong> All trademarks, logos, and
                service marks displayed on the Platform are the property of
                MegaGen America or their respective owners. You are not
                permitted to use these trademarks without our prior written
                consent or the consent of the respective owners.
              </p>
              <h5 className="title">4.User Account</h5>
              <p>
                You are responsible for maintaining the confidentiality of your
                account information and for all activities that occur under your
                account. We reserve the right to terminate or suspend your
                access to the Platform at any time, without notice, for conduct
                that we believe violates these Terms or is harmful to other
                users of the Platform, us, or third parties, or for any other
                reason.
              </p>
              <h5 className="title">5. Limitation of Liability</h5>
              <p>
                To the fullest extent permitted by applicable law, MegaGen
                America shall not be liable for any indirect, incidental,
                special, consequential, or punitive damages, or any loss of
                profits or revenues, whether incurred directly or indirectly, or
                any loss of data, use, goodwill, or other intangible losses,
                resulting from a) your use or inability to use the Platform; b)
                any unauthorized access to or use of our servers and/or any
                personal information stored therein; c) any interruption or
                cessation of transmission to or from the Platform; d) any bugs,
                viruses, trojan horses, or the like that may be transmitted to
                or through our Platform by any third party; or e) any errors or
                omissions in any Content or for any loss or damage of any kind
                incurred as a result of your use of any Content posted, emailed,
                transmitted, or otherwise made available via the Platform,
                whether based on warranty, contract, tort (including
                negligence), or any other legal theory, and whether or not
                MegaGen America has been advised of the possibility of such
                damages.
              </p>
              <h5 className="title">6. Governing Law</h5>
              <p>
                These terms and conditions shall be governed by and construed in
                accordance with the laws of the state of New Jersey, USA,
                without regard to its conflict of law principles.
              </p>
              <h5 className="title"> 7. Changes to Terms</h5>
              <p>
                We reserve the right to modify these Terms at any time. Any
                changes will be effective immediately upon posting the revised
                Terms on the Platform. Your continued use of the Platform
                following the posting of changes constitutes your acceptance of
                such changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ToC;
