import React, { useEffect, useState } from "react";
import { API_URL2 } from "../../api/APIUrl";
import FsLightbox from "fslightbox-react";
import Modal from "react-modal";

const AdminAnalytics = ({ visible }) => {
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [visibleUserAnalysis, setVisibleUserAnalysis] = useState(false);
  const [visiblePostAnalysis, setVisiblePostAnalysis] = useState(true);

  const itemsPerPage = 10;
  const finalIndex = currentPage * itemsPerPage;
  const startingIndex = finalIndex - itemsPerPage;

  function getAllPosts() {
    fetch(`${API_URL2}/api/posts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setPosts(data.reverse());
      });
  }
  function getAllUsers() {
    fetch(`${API_URL2}/api/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setUsers(data);
      });
  }
  useEffect(() => {
    getAllPosts();
    getAllUsers();
  }, []);

  const items = posts.slice(startingIndex, finalIndex);
  const npage = Math.ceil(posts.length / itemsPerPage);
  const npage2 = Math.ceil(users.length / itemsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function getPageRange(current, total) {
    const range = [];

    if (total <= 6) {
      for (let i = 1; i <= total; i++) {
        range.push(i);
      }
    } else {
      range.push(1);
      if (current > 4) {
        range.push("...");
      }
      if (current > 3 && current < total - 2) {
        range.push(current - 1, current, current + 1);
      } else if (current <= 3) {
        range.push(2, 3, 4);
      } else {
        range.push(total - 3, total - 2, total - 1);
      }
      if (current < total - 3) {
        range.push("...");
      }
      range.push(total);
    }

    return range;
  }

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...posts];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems.slice(startingIndex, finalIndex);
  }, [posts, sortConfig, startingIndex, finalIndex]);

  const sortedItems2 = React.useMemo(() => {
    let sortableItems2 = [...users];
    if (sortConfig.key !== null) {
      sortableItems2.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems2.slice(startingIndex, finalIndex);
  }, [posts, sortConfig, startingIndex, finalIndex]);
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="container">
      <div
        className={`recent-orders row justify-content-center mt--70 ${
          visible === false ? "d-none" : ""
        } `}
      >
        <div className="text-end mb--20">
          <a
            style={{ display: "inline-block" }}
            onClick={() => {
              setVisiblePostAnalysis(true);
              setVisibleUserAnalysis(false);
              setCurrentPage(1);
            }}
          >
            <button className="rn-btn btn-default btn-medium mr--10 ml--10 mb--5">
              <span>Post Analysis</span>
              <i className="icon-arrow-right-line-right"></i>
            </button>
          </a>
          <a
            style={{ display: "inline-block" }}
            onClick={() => {
              setVisiblePostAnalysis(false);
              setVisibleUserAnalysis(true);
              setCurrentPage(1);
            }}
          >
            <button className="rn-btn btn-default btn-medium mr--10 ml--10 mb--5">
              <span>User Analysis</span>
              <i className="icon-arrow-right-line-right"></i>
            </button>
          </a>
        </div>
        <div className={`${visiblePostAnalysis === true ? "" : "d-none"}`}>
          <h1 className="text-center">Post Analysis</h1>{" "}
          <table className={` styled-table `}>
            <thead>
              <tr>
                <th onClick={() => requestSort("postTitle")}>Post Title</th>
                <th onClick={() => requestSort("category")}>Category</th>
                <th onClick={() => requestSort("downloadCount")}>
                  Download Count
                </th>
              </tr>
            </thead>

            {sortedItems.map((post, index) => (
              <tbody key={post.id}>
                <tr>
                  <td data-label="Post Title">{post.postTitle}</td>
                  <td data-label="Category">{post.category}</td>
                  <td data-label="Download Count">{post.downloadCount}</td>
                </tr>
              </tbody>
            ))}
          </table>
          <div className="pagination">
            <button
              className="btn-default btn-white btn-small"
              onClick={prePage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {getPageRange(currentPage, npage).map((page, index) =>
              page === "..." ? (
                <span key={index}>...</span>
              ) : (
                <button
                  key={index}
                  onClick={() => changeCPage(page)}
                  className={`btn-default btn-white btn-small ${
                    currentPage === page ? "active" : ""
                  }`}
                  style={{ margin: "5px" }}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="btn-default btn-white btn-small"
              onClick={nextPage}
              disabled={currentPage === npage}
            >
              Next
            </button>
          </div>
        </div>
        <div className={`${visibleUserAnalysis === true ? "" : "d-none"}`}>
          <h1 className="text-center">User Analysis</h1>{" "}
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Country</th>
                <th>Email</th>
                <th onClick={() => requestSort("downloadCount")}>
                  Download Count
                </th>
              </tr>
            </thead>

            {sortedItems2.map((user, index) => (
              <tbody>
                <tr>
                  <td data-label="Username">{user.username}</td>{" "}
                  <td data-label="Country">{user.country}</td>
                  <td data-label="Email">{user.email}</td>{" "}
                  <td data-label="Download Count">{user.downloadCount}</td>
                </tr>
              </tbody>
            ))}
          </table>
          <div className="pagination">
            <button
              className="btn-default btn-white btn-small"
              onClick={prePage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {getPageRange(currentPage, npage2).map((page, index) =>
              page === "..." ? (
                <span key={index}>...</span>
              ) : (
                <button
                  key={index}
                  onClick={() => changeCPage(page)}
                  className={`btn-default btn-white btn-small ${
                    currentPage === page ? "active" : ""
                  }`}
                  style={{ margin: "5px" }}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="btn-default btn-white btn-small"
              onClick={nextPage}
              disabled={currentPage === npage2}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAnalytics;
