import React, { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import StickyHeader from "./StickyHeader";
import MobileMenu from "./MobileMenu";
import Auth from "../../utils/auth";

const Header = ({ styles, disableStickyHeader }) => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const canvasHandler = () => {
    setShowOffCanvas((prevState) => !prevState);
  };

  const sticky = StickyHeader(118);
  const classes = sticky ? "sticky" : "";
  const stickyStat = disableStickyHeader ? "" : "sticky-header";

  return (
    <>
      <header
        className={`main-header disable-transparent ${stickyStat} ${
          styles || ""
        } ${classes || ""}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-xl-2 col-md-6 col-6">
              <div className="logo">
                <a href="/">
                  <img
                    src="/images/logo/MEGAGEN_LOGO_black.webp"
                    alt="megagen logo"
                  />
                </a>
              </div>
            </div>

            <div className="col-lg-8 d-none d-xl-block">
              <nav className="main-nav d-none d-lg-block">
                <Nav />
              </nav>
            </div>

            <div className="col-lg-6 col-xl-2 col-md-6 col-6">
              <div className="header-right d-flex justify-content-end">
                <div className="header-quote">
                  <div className="quote-icon quote-user">
                    <button onClick={() => Auth.logout()}>
                      <i className="ri-user-line">
                        <span className="login-button-text"> Sign Out</span>
                      </i>
                    </button>
                  </div>
                  <div className="hamberger quote-icon d-block d-xl-none">
                    <button
                      className="hamberger-button"
                      onClick={canvasHandler}
                    >
                      <i className="ri-menu-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={showOffCanvas} onClose={canvasHandler} />
    </>
  );
};

export default Header;
